import React from 'react'
import CatList from '../../components/Pages/ProductsList/CatList'


const Main = ({promo}) => {
  return (
    <div className='flex flex-col'>
        <CatList promo={promo} />
    </div>
  )
}

export default Main