import React from 'react';
import Dropdown from './DropDown';

const ProductDetails = ({ description, nutritionalInfo, feedingInstructions }) => {
  return (
    <div className="w-full mt-6">
      <Dropdown title="Product Description">
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Dropdown>

      <Dropdown title="Nutritional Info">
        <div dangerouslySetInnerHTML={{ __html: nutritionalInfo }} />
      </Dropdown>

      <Dropdown title="Feeding Instructions">
        <div dangerouslySetInnerHTML={{ __html: feedingInstructions }} />
      </Dropdown>
    </div>
  );
};

export default ProductDetails;