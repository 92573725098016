// src/components/CartList.jsx
import React from 'react';
import { useAtom } from 'jotai';

import CartItem from './CartItem';
import { cartAtom } from '../../../cartStore';
import { Link } from 'react-router-dom';
import CheckoutItem from './CheckoutItem';

const CheckoutList = () => {
  const [cart] = useAtom(cartAtom);

  return (
    <div className="max-w-md p-4 mx-auto mt-4 ">

      {cart.length === 0 ? (
        <p className="text-gray-600">Your cart is empty.</p>
      ) : (
        cart.map((item) => <CheckoutItem key={`${item.id}-${item.weight}`} item={item} />)
      )}
    </div>
  );
};

export default CheckoutList;
