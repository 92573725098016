// src/cartStore.js
import { atom, useAtom } from 'jotai';

export const cartAtom = atom([]);

export const subtotalAtom = atom((get) =>
  get(cartAtom).reduce((total, item) => total + item.price * item.quantity, 0)
);

export const useCart = () => {
  const [cart, setCart] = useAtom(cartAtom);

  const addItem = (item) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (i) => i.id === item.id && i.weight === item.weight
      );
      if (existingItemIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex] = {
          ...prevCart[existingItemIndex],
          quantity: prevCart[existingItemIndex].quantity + item.quantity,
        };
        return updatedCart;
      }
      return [...prevCart, { ...item, quantity: Math.max(1, item.quantity || 1) }];
    });
  };

  const removeItem = (id, weight) => {
    setCart((prevCart) => prevCart.filter((item) => !(item.id === id && item.weight === weight)));
  };

  const updateItemQuantity = (id, weight, quantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id && item.weight === weight
          ? { ...item, quantity: Math.max(1, quantity) }
          : item
      )
    );
  };

  return { cart, addItem, removeItem, updateItemQuantity };
};
