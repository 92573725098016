import React, { useState } from 'react';

const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b">
      <button
        onClick={toggleDropdown}
        className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
      >
        <span className="text-lg font-semibold">{title}</span>
        <span className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
          <svg
            className="w-4 h-4 text-[#103386]"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path fillRule="evenodd" d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </button>
      <div className={`transition-all duration-300 overflow-y-scroll ${isOpen ? 'max-h-96' : 'max-h-0'}`}>
        <div className="py-4 text-sm text-gray-700  h-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
