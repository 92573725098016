// src/components/Cart.jsx
import React from 'react';
import { useAtom } from 'jotai';
import { subtotalAtom } from '../../cartStore';
import CartList from '../../components/Pages/Cart/CartList';
import { Link } from 'react-router-dom';

const Cart = () => {
  const [subtotal] = useAtom(subtotalAtom); // Jotai hook to read the subtotal atom
  const currency = 'KD';

  return (
    <div className="w-full max-w-md p-6 mx-auto bg-white rounded-lg shadow-md">
      <h2 className="mb-4 text-2xl font-semibold text-gray-900">Shopping Cart</h2>

      <div className="py-4 mb-4 border-t border-b">
        <div className="text-lg font-semibold text-gray-700">Order Summary</div>
      </div>

      <div className="flex justify-between py-2 text-gray-600">
        <span>Subtotal</span>
        <span>
          {currency} {subtotal.toFixed(3)}
        </span>
      </div>

      <div className="flex justify-between py-2 text-gray-600">
        <span>You Earn</span>
        <span>
          {currency} {(subtotal * 0.1).toFixed(3)} {/* Example earning calculation */}
        </span>
      </div>

      <hr className="my-4" />

      <div className="flex justify-between py-2 font-bold text-gray-900">
        <span>Price Details</span>
        <span>
          {currency} {subtotal.toFixed(3)}
        </span>
      </div>

      <Link to={'/checkout'} className="w-full py-3 flex justify-center mt-6 text-white bg-[#103386] rounded-full text-lg font-semibold hover:bg-blue-700">
        Proceed to Checkout
      </Link>
      <CartList />
    </div>
  );
};

export default Cart;
