import React from 'react';

const InputField = React.forwardRef(({ value, onChange, onFocus, formatType, placeholder, focused, hidden }, ref) => {
    let formattedValue;
    if (formatType === 'card') {
        formattedValue = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
    } else if (formatType === 'date') {
      // Format date as MM/YY if there are at least 3 characters
      formattedValue = value.length > 2 ? `${value.slice(0, 2)}/${value.slice(2)}` : value;
    } else {
        formattedValue = value;
    }

    const handleInputChange = (e) => {
        let newValue = e.target.value;
        if (formatType === 'card') {
            newValue = newValue.replace(/\D/g, '').slice(0, 16); // Restrict to 16 digits
        } else if (formatType === 'date') {
            newValue = newValue.replace(/\D/g, '').slice(0, 4); // Restrict to 4 digits (MMYY)
        } else if (formatType === 'code') {
            newValue = newValue.replace(/\D/g, '').slice(0, 3); // Restrict to 3 digits
        }
        onChange({ target: { value: newValue } });
    };

    return (
        <input
            ref={ref}
            type="tel"
            hidden={hidden}
            value={formattedValue}
            onChange={handleInputChange} // Use the new handler to allow manual input
            onFocus={onFocus}
            className={formatType === 'num' ? (`px-1 flex items-center justify-center w-10 text-3xl rounded-none font-semibold text-black focus:outline-none input-field placeholder:font-thin placeholder:text-lg ${focused ? ('border-slate-600') : ('border-slate-200')}`)
                :
                (`w-full relative mr-2 text-xl rounded-none bg-white text-black focus:outline-none input-field placeholder:text-base placeholder:font-thin ${focused ? (formatType === 'card' ? ('border-slate-200 max-w-44 overflow-x-scroll z-50') : ('border-slate-200 max-w-14 z-50')) : ('border-slate-200 max-w-14 z-40')}`)}
            placeholder={placeholder}
        />
    );
});

export default InputField;
