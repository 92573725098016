import React, { useState } from "react"
import cat_food from "../../../assets/products/catfood.json"
import dog_food from "../../../assets/products/dogfood.json"
import { Link } from "react-router-dom"

const CatList = ({ promo }) => {
  const cat_products = cat_food.collection.products.nodes
  const dog_products = dog_food.collection.products.nodes
  const [products, set_products] = useState(cat_products)
  const [selected, set_selected] = useState("Cat")

  return (
    <div className="flex flex-col p-4">
      <img
        className="w-full rounded-lg"
        src="/assets/offers.png"
        alt=""
      />

      <div className="grid w-full grid-cols-2 gap-2 mt-3">
        <div
          onClick={() => {
            set_products(dog_products)
            set_selected("Dog")
          }}
          className={`flex flex-col w-full p-4 border rounded-lg ${
            selected === "Dog" && "border border-blue-600"
          }`}
        >
          <img
            className="object-contain w-full px-6"
            src="/assets/img/dov.png"
            alt=""
          />
          <span className="text-[#103386]">Dog</span>
        </div>
        <div
          onClick={() => {
            set_products(cat_products)
            set_selected("Cat")
          }}
          className={`flex flex-col w-full p-4 border rounded-lg ${
            selected === "Cat" && "border border-blue-600"
          }`}
        >
          <img
            className="object-contain w-full px-6"
            src="/assets/img/cat.png"
            alt=""
          />
          <span className="text-[#103386]">Cat</span>
        </div>
      </div>

      <span className="mt-4 text-lg font-semibold">{selected} Food Offers</span>
      <span className="text-[#252525] mb-2">
        Showing {products.length} products
      </span>

      <div className="grid grid-cols-2 gap-4 ">
        {products
          ? products.map((product) => (
              <Link
                to={`/product/${selected === 'Dog' ? 'dog_products' : 'cat_products'}/${product.handle}`}
                className="flex flex-col rounded-lg border shadow-md shadow-[#26273b12]"
              >
                <img
                  className="object-contain w-full h-40"
                  src={product.images.edges[0].node.originalSrc}
                  alt=""
                />
                <div className="flex flex-col justify-between w-full h-full p-4">
                  <div className="flex flex-col">
                    <span className="text-sm text-[#89b340]">In Stock</span>
                    <span className="text-[#142461] font-medium mt-2 text-[15px]">
                      {product.title}
                    </span>
                    <div className="flex items-center">
                        
                    <span className="text-[#6b6c7d] line-through text-[13px]">
                      {product.variants.nodes[0].price.currencyCode}{" "}
                      {product.variants.nodes[0].price.amount}
                    </span>

                    <span className="px-[3px] py-[1px] bg-red-500 rounded text-white ml-2 text-[10px]">-{(100-(promo*100)).toFixed()}%</span>
                    </div>
                    <span className="text-[#6b6c7d] text-[17px]">
                      {product.variants.nodes[0].price.currencyCode}{" "}
                      {(product.variants.nodes[0].price.amount * promo).toFixed(3)}
                    </span>
                  </div>
                  <button className="w-full mt-4 p-1 bg-[#103386] rounded-full text-white font-semibold">
                    Add to Cart
                  </button>
                </div>
              </Link>
            ))
          : "Loading..."}
      </div>
    </div>
  )
}

export default CatList
