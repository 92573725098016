// src/components/CartItem.jsx
import React from 'react';
import { useCart } from '../../../cartStore';


const CheckoutItem = ({ item }) => {
  const { removeItem, updateItemQuantity } = useCart();

  const handleQuantityChange = (change) => {
    updateItemQuantity(item.id, item.weight, item.quantity + change);
  };

  return (
    <div className="flex items-center py-4 border-b">
      <img src={item.image} alt={item.name} className="w-16 h-16 mr-4" />
      <div className="flex-grow">
        <div className="flex justify-between">
          <div>
            <span className="text-sm text-green-600">In Stock</span>
            <h3 className="font-semibold text-gray-900">{item.name}</h3>
          </div>

        </div>
        <div className="flex items-center mt-2 text-gray-700">
          <span className="mr-2 text-lg font-bold">
            KD {(item.price * item.quantity).toFixed(3)}
          </span>
        </div>

      </div>
    </div>
  );
};

export default CheckoutItem;
