// src/components/CartList.jsx
import React from 'react';
import { useAtom } from 'jotai';

import CartItem from './CartItem';
import { cartAtom } from '../../../cartStore';
import { Link } from 'react-router-dom';

const CartList = () => {
  const [cart] = useAtom(cartAtom);

  return (
    <div className="max-w-md p-4 mx-auto mt-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold text-gray-800">Your cart {cart.length} item(s)</h2>
      <Link to="/" className="inline-block mb-4 text-sm text-blue-600">
        Continue shopping
      </Link>
      {cart.length === 0 ? (
        <p className="text-gray-600">Your cart is empty.</p>
      ) : (
        cart.map((item) => <CartItem key={`${item.id}-${item.weight}`} item={item} />)
      )}
    </div>
  );
};

export default CartList;
