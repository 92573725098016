// src/components/CatProduct.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dog_food from '../../../../assets/products/dogfood.json';
import ImageGallery from '../../../Global/ImageSlider';
import ProductMenu from './Components/ProductMenu';
import ProductDetails from './Components/ProductDetails';
import RelatedProducts from './Components/RelatedProducts';
import { useCart } from '../../../../cartStore';

const DogProduct = ({promo}) => {
  const products = dog_food.collection.products.nodes;
  const { handle } = useParams();
  const [product, setProduct] = useState(null);
  const { addItem } = useCart();
  const navigate = useNavigate()
  const [add_btn, set_add_btn] = useState('Add to cart')

  const handleAddToCart = (price, quantity, weight) => {
    if (!product) return;

    set_add_btn(<div className='inputLoader'></div>)

    addItem({
      id: product.id,
      price: (parseFloat(price.amount))*promo, // Ensure price is a number
      name: product.title,
      quantity,
      image: product.media.nodes[0].image.url,
      weight,
    });
    setTimeout(()=>{
      navigate('/cart')
    },2000)

    console.log(`${product.title} added to cart with quantity ${quantity} and size ${weight}`);
  };

  useEffect(() => {
    const matchingProduct = products.find((product) => product.handle === handle);
    if (matchingProduct) {
      setProduct(matchingProduct);
    } else {
      console.error('Product not found');
    }
  }, [handle, products]);

  return(
    <div className="flex flex-col w-full p-4">
      {product ? (
        <div className="flex flex-col">
          <ImageGallery images={product.media.nodes.map((node) => node.image.url)} />
          <span className="text-2xl text-[#111827]">{product.title}</span>
          <span className=" text-[#6b6c7d]">by {product.vendor}</span>
          <span className=" text-sm text-[#89b340]">In Stock</span>

          <ProductMenu
            price={product.variants.nodes[0].price}
            variants={product.variants.nodes}
            sizes={product.options[1].values}
            handleAddToCart={handleAddToCart}
            add_btn={add_btn}
            promo={promo}
          />



          <ProductDetails
            description={product.description}
            nutritionalInfo={product.nutritionalInfo}
            feedingInstructions={product.feedingInstructions}
          />

          <RelatedProducts />
        </div>
      ) : (
        'Loading...'
      )}
    </div>
  );
};

export default DogProduct;
