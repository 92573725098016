import React, { useState, useEffect } from 'react';
import './StatusMessages.css'; // Import your CSS file for styling

const StatusMessages = () => {

  // const messages = [
  //   "Twoje dane płatności są bezpiecznie szyfrowane...",
  //   "Weryfikowanie danych płatności...",
  //   "Dziękujemy za cierpliwość...",
  //   "Prawie gotowe—jeszcze chwilę...",
  //   "Twoje bezpieczeństwo jest dla nas priorytetem...",
  //   "Przetwarzanie transakcji w bezpieczny sposób...",
  //   "Weryfikacja płatności w toku...",
  //   "Doceniamy Twoje zrozumienie...",
  //   "Pozostań cierpliwy, podczas gdy finalizujemy...",
  //   "Dziękujemy za wybranie nas..."
  // ];
  const messages = [    "Your payment details are securely encrypted...",
    "Verifying your payment details...",
    "Thank you for your patience...",
    "Almost there—just a moment more...",
    "Your security is our priority...",
    "Processing your transaction securely...",
    "Payment validation in progress...",
    "We appreciate your understanding...",
    "Sit tight while we finalize...",
    "Thank you for choosing us..."];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(true); // Start fading in the next message

      // Delay to start fading out the current message
      setTimeout(() => {
        setIsVisible(false); // Start fading out the current message

        // Delay to rotate to the next message
        setTimeout(() => {
          setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length); // Rotate to the next message
        }, 800); // Time for fade out transition (adjust as needed)

      }, 4000); // Time for showing each message (adjust as needed)

    }, 5000); // Change message every 3 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [messages.length]); // useEffect dependency to handle message rotation

  return (
    <div className="flex items-center justify-center w-full mt-4 ">

      <div className="text-[#78849e]">
        <div className="relative flex flex-col items-center status-message">
          <p className={`text-sm md:text-base md:w-52 w-32 absolute text-center ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            {messages[currentMessageIndex]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatusMessages;
