import React, { useState, useEffect } from "react";

const ProductMenu = ({ price, sizes, variants, handleAddToCart, add_btn, promo }) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(sizes[0]);
  const [displayedPrice, setDisplayedPrice] = useState(price); // Initialize with the first variant price

  console.log(sizes)
  // Update price when selectedSize changes
  useEffect(() => {
    const variant = variants.find(
      (variant) =>
        variant.selectedOptions.some(
          (option) => option.name === "Size" && option.value === selectedSize
        )
    );
    if (variant) {
      setDisplayedPrice(variant.price);
    }
  }, [selectedSize, variants]);

  const handleQuantityChange = (change) => {
    setQuantity((prev) => Math.max(1, prev + change));
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  return (
    <div className="w-full mt-4 rounded-lg shadow-sm">
      {/* Size Section */}
      <div className="w-full">
        <h2 className="mb-2 text-lg font-semibold">Size</h2>
        <div className="flex space-x-2">
          {sizes.map((size) => (
            <button
              key={size}
              onClick={() => handleSizeClick(size)}
              className={`px-4 py-2 border rounded-md text-sm ${
                selectedSize === size
                  ? "border-gray-700 bg-gray-100"
                  : "border-gray-300 bg-gray-50 hover:border-gray-400"
              }`}
            >
              {size}
            </button>
          ))}
        </div>
      </div>

      {/* Price Section */}
      <div className="mt-4">
        <div className="text-sm text-gray-500">Price</div>
        <div className="font-semibold text-gray-800 line-through ">
          {displayedPrice.currencyCode} {(displayedPrice.amount).toFixed(3)}
        </div>
        <div className="text-xl font-semibold text-gray-800">
          {displayedPrice.currencyCode} {(displayedPrice.amount*promo).toFixed(3)}
        </div>
      </div>

      {/* Quantity Section */}
      <div className="mt-4">
        <div className="mb-1 text-sm text-gray-500">Quantity</div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => handleQuantityChange(-1)}
            className="flex items-center justify-center w-8 h-8 text-gray-600 border border-gray-300 rounded-full"
          >
            -
          </button>
          <span className="flex items-center justify-center w-8 h-8 text-gray-800 border border-gray-300 rounded-lg">
            {quantity}
          </span>
          <button
            onClick={() => handleQuantityChange(1)}
            className="flex items-center justify-center w-8 h-8 text-gray-600 border border-gray-300 rounded-full"
          >
            +
          </button>
        </div>
      </div>

      {/* Add to Cart Button */}
      <button onClick={()=>handleAddToCart(displayedPrice,quantity,selectedSize)} className="w-full mt-6 py-4 bg-[#103386] text-white rounded-full font-semibold hover:bg-blue-700">
        {add_btn}
      </button>

      {/* Favorites and Share Buttons */}
      <div className="flex justify-between mt-4 space-x-2">
        <button className="flex items-center justify-center w-full py-2 border border-[#103386] rounded-full text-gray-600 hover:bg-gray-100">
          <svg
            className="block mr-2 md:hidden"
            width="24"
            height="24"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.2194 29.5163C15.6392 29.5196 15.0652 29.3984 14.5359 29.1608C14.0067 28.9234 13.5345 28.5752 13.1512 28.1396L3.60667 17.3069C2.22539 15.9113 1.33775 14.1026 1.07875 12.1561C0.81975 10.2097 1.20357 8.23184 2.17183 6.52352C2.74456 5.43786 3.54245 4.48697 4.51215 3.73439C5.48186 2.98183 6.60103 2.44493 7.79488 2.15959C9.00177 1.9182 10.2475 1.951 11.44 2.25559C12.6325 2.56017 13.7413 3.12874 14.6846 3.91932C15.243 4.37812 15.7439 4.90277 16.1764 5.48184C16.5378 4.97795 16.9533 4.51505 17.4153 4.10136C18.2436 3.3492 19.2239 2.7841 20.29 2.44446C21.356 2.10483 22.4826 1.99862 23.5933 2.13304C25.4537 2.40179 27.1746 3.27286 28.4927 4.61288C29.8108 5.9529 30.6533 7.68799 30.8914 9.55249C31.1041 10.9461 31.0035 12.3695 30.5967 13.7192C30.19 15.0689 29.4874 16.311 28.54 17.355L19.266 28.1068C18.8875 28.5461 18.4193 28.899 17.8929 29.1423C17.3665 29.3854 16.7942 29.5129 16.2143 29.5163H16.2194Z"
              stroke="#304659"
              stroke-miterlimit="10"
            ></path>
          </svg>
          Favorites
        </button>
        <button className="flex items-center justify-center w-full py-2 border border-[#103386] rounded-full text-gray-600 hover:bg-gray-100">
          <svg
            className="mr-2"
            width="32"
            height="30"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.4 12.3C18.2 12.3 16.7 12.3 15 12.6C13.8 12.8 12.7 13.2 11.7 13.8C10.4 14.7 9.7 16 9.3 17.6C9 18.9 8.9 20.3 9 21.7C9 22.4 9.1 23.1 9.2 23.8C9.2 24.1 9.1 24.4 8.8 24.5C8.5 24.6 8.2 24.5 8 24.2C7.6 23.6 7.3 23 6.9 22.4C6 20.8 5.3 19.2 5 17.5C4.8 16.6 4.9 15.5 4.9 14.5C5 13.4 5.2 12.3 5.5 11.3C6.2 9.39998 7.4 7.99998 9.2 6.89998C10.8 5.99998 12.4 5.49998 14.3 5.19998C15.6 4.99998 16.9 4.89998 18.3 4.89998C19.4 4.89998 20.5 4.89998 21.8 4.89998C22.1 4.89998 22.2 4.79998 22.2 4.49998C22.2 3.49998 22.2 2.49998 22.2 1.49998C22.2 0.999977 22.3 0.499977 22.8 0.199977C23.4 -0.100023 23.9 -0.100023 24.4 0.399977C25.4 1.39998 26.3 2.39998 27.2 3.29998C28.6 4.69998 30.1 6.19998 31.6 7.69998C32.3 8.39998 32.3 8.99998 31.6 9.69998C29.2 12.1 26.7 14.6 24.4 16.9C23.8 17.5 22.7 17.3 22.4 16.5C22.3 16.3 22.3 16 22.3 15.7C22.3 14.7 22.3 13.7 22.3 12.7C22.3 12.4 22.2 12.3 21.9 12.4C21 12.3 20.4 12.3 19.4 12.3Z"
              fill="#304659"
            />
            <path
              d="M13.5 29.5C10.8 29.5 8 29.5 5.4 29.5C3.6 29.5 2.2 28.7 1.1 27.3C0.3 26.3 0 25.2 0 24C0 18.6 0 13.2 0 8.00002C0 5.00002 2.4 2.60002 4.9 2.40002C5.2 2.40002 5.6 2.40002 5.9 2.40002C7.3 2.40002 8.8 2.40002 10.3 2.40002C10.5 2.40002 10.8 2.40002 10.9 2.60002C11.2 2.90002 11.1 3.40002 10.7 3.60002C9.7 3.90002 8.8 4.30002 8 4.80002C7.8 4.90002 7.6 4.90002 7.3 4.90002C6.6 4.90002 5.9 4.90002 5.2 4.90002C3.8 4.90002 2.5 6.20002 2.5 7.60002C2.4 8.70002 2.5 9.90003 2.5 11.1C2.5 12 2.5 12.9 2.5 13.8C2.5 14.7 2.5 15.7 2.5 16.6C2.5 17.5 2.5 18.4 2.5 19.3C2.5 20.2 2.5 21.2 2.5 22.1C2.5 22.4 2.5 22.7 2.5 22.9C2.5 23.8 2.5 24.7 3.1 25.5C3.8 26.6 4.6 27 5.6 27C10.9 27 16.2 27 21.4 27C22.8 27 24 26.1 24.3 24.7C24.4 24.4 24.4 24.1 24.4 23.7C24.4 22.5 24.4 21.2 24.4 19.9C24.4 19.6 24.5 19.3 24.8 19.1C25.2 18.9 25.5 18.7 25.8 18.4C26 18.2 26.2 18.2 26.5 18.3C26.7 18.4 26.9 18.6 26.9 18.9C26.9 20.7 27 22.6 26.9 24.4C26.8 26 26 27.3 24.7 28.4C23.8 29.1 22.7 29.5 21.6 29.5C21.5 29.5 21.5 29.5 21.4 29.5C18.8 29.5 16.1 29.5 13.5 29.5Z"
              fill="#304659"
            />
          </svg>
          Share
        </button>
      </div>
    </div>
  );
};

export default ProductMenu;
