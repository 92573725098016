import React, { useState, useEffect } from 'react';
import CountdownTimer from '../CountDown/CountDownTimer';

const PromoOverlay = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);

  // Check local storage to determine if the overlay should show
  useEffect(() => {
    const isPromoDismissed = localStorage.getItem('promoDismissed');
    if (!isPromoDismissed) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsFading(true); // Start fade-out effect
    setTimeout(() => {
      setIsVisible(false); // Remove overlay after animation
      localStorage.setItem('promoDismissed', 'true'); // Save dismissal in local storage
    }, 500); // Match duration with CSS transition
  };

  return (
    isVisible && (
      <div
        className={`absolute flex items-center justify-center w-full h-screen p-8 bg-black backdrop-blur-md bg-opacity-60 transition-opacity duration-500 ${
          isFading ? 'opacity-0' : 'opacity-100'
        }`}
      >
        <div className="w-full h-auto max-w-md overflow-hidden bg-white shadow-lg rounded-xl">
          <img src="/assets/offers.png" className="object-cover w-full h-24" alt="Promo Offer" />
          <div className="p-6 text-center">
            <h2 className="text-3xl font-bold text-yellow-600">🎉 Happy Hours! 🎉</h2>
            <p className="mt-4 text-lg text-gray-700">
              Enjoy <span className="font-bold text-red-600">-45% off</span> on all your purchases!
            </p>
            <p className="mt-2 text-gray-500">Hurry, the offer ends when the timer runs out! ⏳</p>
            <CountdownTimer />
            <button
              className="w-full px-6 py-3 mt-6 text-lg font-semibold text-white bg-yellow-500 rounded-lg hover:bg-yellow-600"
              onClick={handleClose}
            >
              Shop Now
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PromoOverlay;
