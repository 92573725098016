import React from 'react'
import cat_food from '../../../../../assets/products/catfood.json'
import { Link } from 'react-router-dom'
const RelatedProducts = () => {
    const products = cat_food.collection.products.nodes
  return (
    <div className='mt-6'>
        <span className='text-lg font-semibold mb-2'>Related Products</span>
        <div className="flex w-full gap-x-4 overflow-x-scroll">
{        products ? (
                products.slice(-10).map(product => (
                    
                    <Link to={`/product/cat_products/${product.handle}`} className="flex flex-col w-1/2 rounded-lg border shadow-md flex-shrink-0 shadow-[#26273b12]">
                    <img className='w-full h-40 object-contain' src={product.images.edges[0].node.originalSrc} alt="" />
                    <div className="w-full flex flex-col h-full justify-between p-4">
                        <div className="flex flex-col">

                    <span className='text-sm text-[#89b340]'>In Stock</span>
                    <span className='text-[#142461] font-medium mt-2 text-[15px]'>{product.title}</span>
                    <span className='text-[#6b6c7d] text-[15px]'>{product.variants.nodes[0].price.currencyCode} {product.variants.nodes[0].price.amount}</span>
                        </div>
                    <button className='w-full mt-4 p-1 bg-[#103386] rounded-full text-white font-semibold'>Add to Cart</button>
                    </div>
                    
                </Link>
                )
            )
        ) : ('Loading...')}
        </div>
    </div>
  )
}

export default RelatedProducts