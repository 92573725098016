import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageGallery = ({ images }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const mainSettings = {
    asNavFor: nav2,
    ref: slider1,
    arrows: false,
    fade: true,
    infinite: true,
  };

  const thumbSettings = {
    asNavFor: nav1,
    ref: slider2,
    slidesToShow: Math.min(images.length, 4), // Display up to 4 thumbnails or less if fewer images
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    infinite: true,
  };

  return (
    <div className="w-full">
      {/* Main Image Slider */}
      <Slider {...mainSettings}>
        {images.map((image, index) => (
          <div key={index} className="p-4">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-96 object-cover rounded-lg"
            />
          </div>
        ))}
      </Slider>

      {/* Thumbnail Slider */}
      <Slider {...thumbSettings} className="mt-4">
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <img
              src={image}
              alt={`Thumbnail ${index}`}
              className="w-20 h-20 object-cover rounded-lg cursor-pointer"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageGallery;
