import React from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => {
  return (
    <div className='flex justify-between w-full p-2 bg-white shadow'>
      <div className="w-10"></div>
        <Link to='/'>
        <svg width="189" height="50" viewBox="0 0 189 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M162.31 11.78C162.31 11.78 163.17 8.04001 165.06 6.23001C167.91 3.51001 169.82 6.32001 169.11 11.2C168.45 15.69 165.46 18.66 163.39 16.97C161.32 15.27 162.31 11.78 162.31 11.78Z" fill="#694FA0"/>
<path d="M171.91 16.25C171.91 16.25 174.25 13.21 176.73 12.35C180.46 11.06 181.03 14.4 178.34 18.54C175.87 22.35 171.9 23.81 170.73 21.41C169.56 19.01 171.91 16.25 171.91 16.25Z" fill="#80C343"/>
<path d="M183.88 26.49C183.88 26.49 181.07 28.53 178.75 28.67C175.26 28.88 175.72 25.91 179.12 23.01C182.25 20.35 185.99 20.05 186.3 22.37C186.62 24.69 183.87 26.49 183.87 26.49" fill="#F57E20"/>
<path d="M185.14 34.84C185.14 34.84 182.82 35.36 181.23 34.67C178.84 33.63 179.78 31.63 182.68 30.7C185.35 29.84 187.92 30.9 187.64 32.69C187.36 34.48 185.14 34.84 185.14 34.84Z" fill="#FBB416"/>
<path d="M177.68 32.43C174.83 29.36 172.38 30.53 169.41 26.73C167.43 24.2 168.41 21.48 164.61 19.02C162.21 17.47 158.55 19.51 157.02 21.32C154.7 23.81 153.71 29.19 154.01 32.26C154.21 34.27 154.75 35.88 155.32 37.09C156.18 38.53 157.1 37.25 157.36 36.3C157.98 34 156.49 27.31 159.9 25.42C161.26 24.67 162.55 25.09 163.43 26.11C164.03 26.81 164.88 28.27 165.61 28.89C167.28 30.29 169.04 30.91 170.62 32.25C172.04 33.46 172.41 34.45 171.89 36.04C171.29 37.88 169.57 38.49 168.18 38.58C166.54 38.68 164.79 37.24 163.73 36.05C162.89 35.11 161.51 34.7 160.3 35.3C158.87 36 158.65 37.83 158.44 39.3C158.42 39.43 158.42 39.95 158.44 40C158.55 40.55 158.91 40.72 159 40.76C165.28 43.57 171.69 43.66 176.23 39.86C178.56 37.92 179.93 34.9 177.68 32.42" fill="#35479B"/>
<path d="M39.59 31.29H29.15C29.16 32.5 29.41 33.57 29.88 34.5C30.36 35.43 30.99 36.12 31.78 36.59C32.57 37.06 33.44 37.3 34.39 37.3C35.03 37.3 35.62 37.22 36.14 37.07C36.67 36.92 37.18 36.69 37.67 36.36C38.17 36.04 38.62 35.7 39.04 35.33C39.46 34.96 40 34.47 40.66 33.84C40.93 33.61 41.33 33.49 41.83 33.49C42.38 33.49 42.82 33.64 43.16 33.94C43.5 34.24 43.67 34.66 43.67 35.21C43.67 35.69 43.48 36.25 43.1 36.9C42.72 37.54 42.15 38.16 41.39 38.76C40.62 39.35 39.67 39.85 38.52 40.24C37.37 40.63 36.04 40.83 34.54 40.83C31.11 40.83 28.45 39.85 26.54 37.9C24.64 35.94 23.68 33.29 23.68 29.94C23.68 28.36 23.91 26.9 24.38 25.55C24.85 24.2 25.53 23.04 26.43 22.08C27.33 21.12 28.44 20.37 29.75 19.86C31.07 19.34 32.53 19.09 34.13 19.09C36.22 19.09 38.01 19.53 39.5 20.41C40.99 21.29 42.11 22.42 42.85 23.82C43.59 25.22 43.96 26.64 43.96 28.08C43.96 29.42 43.58 30.29 42.81 30.69C42.04 31.09 40.96 31.29 39.56 31.29M29.12 28.26H38.8C38.67 26.44 38.18 25.07 37.32 24.16C36.47 23.25 35.34 22.8 33.95 22.8C32.56 22.8 31.53 23.26 30.67 24.18C29.82 25.1 29.3 26.46 29.12 28.26Z" fill="#35479B"/>
<path d="M47.5 19.59H48.09V16.39C48.09 15.53 48.11 14.85 48.16 14.36C48.21 13.87 48.34 13.45 48.54 13.1C48.75 12.74 49.05 12.44 49.44 12.21C49.83 11.98 50.27 11.87 50.75 11.87C51.43 11.87 52.04 12.12 52.59 12.63C52.95 12.97 53.19 13.38 53.28 13.87C53.38 14.36 53.43 15.05 53.43 15.95V19.59H55.38C56.14 19.59 56.71 19.77 57.11 20.13C57.51 20.49 57.7 20.95 57.7 21.51C57.7 22.23 57.42 22.73 56.85 23.02C56.28 23.31 55.47 23.45 54.41 23.45H53.41V33.27C53.41 34.1 53.44 34.75 53.5 35.2C53.56 35.65 53.72 36.01 53.97 36.3C54.23 36.58 54.64 36.72 55.21 36.72C55.53 36.72 55.95 36.66 56.48 36.55C57.01 36.44 57.43 36.38 57.73 36.38C58.16 36.38 58.55 36.55 58.89 36.9C59.24 37.24 59.41 37.67 59.41 38.18C59.41 39.04 58.94 39.7 58 40.15C57.06 40.6 55.71 40.84 53.95 40.84C52.19 40.84 51.01 40.56 50.16 40C49.3 39.44 48.73 38.66 48.47 37.67C48.2 36.68 48.07 35.36 48.07 33.7V23.45H47.37C46.6 23.45 46.01 23.27 45.61 22.9C45.2 22.53 45 22.07 45 21.51C45 20.95 45.21 20.49 45.64 20.13C46.06 19.77 46.68 19.59 47.49 19.59" fill="#35479B"/>
<path d="M77.71 25.24L67.93 36.17H78.39C79.24 36.17 79.88 36.37 80.31 36.76C80.74 37.16 80.96 37.67 80.96 38.3C80.96 38.93 80.75 39.39 80.32 39.77C79.9 40.15 79.25 40.34 78.39 40.34H63.92C62.9 40.34 62.14 40.12 61.64 39.67C61.14 39.22 60.89 38.62 60.89 37.85C60.89 37.39 61.07 36.93 61.42 36.47C61.77 36.01 62.5 35.16 63.61 33.92C64.79 32.62 65.85 31.44 66.81 30.38C67.77 29.32 68.66 28.34 69.48 27.42C70.3 26.5 70.98 25.72 71.52 25.08C72.06 24.44 72.49 23.89 72.82 23.44H64.88C63.78 23.44 62.96 23.34 62.4 23.15C61.84 22.96 61.56 22.44 61.56 21.61C61.56 21 61.77 20.51 62.2 20.14C62.62 19.78 63.23 19.59 64.01 19.59H76.27C77.41 19.59 78.27 19.76 78.88 20.09C79.49 20.42 79.79 21.02 79.79 21.88C79.79 22.17 79.73 22.46 79.61 22.77C79.49 23.08 79.36 23.33 79.22 23.53C79.08 23.72 78.88 23.96 78.63 24.24C78.38 24.52 78.08 24.86 77.71 25.25" fill="#80C343"/>
<path d="M103.78 29.98C103.78 31.57 103.54 33.04 103.04 34.38C102.54 35.72 101.83 36.88 100.89 37.84C99.95 38.81 98.83 39.54 97.53 40.06C96.23 40.58 94.76 40.83 93.13 40.83C91.5 40.83 90.06 40.57 88.77 40.05C87.48 39.53 86.36 38.78 85.42 37.81C84.47 36.84 83.76 35.69 83.27 34.37C82.78 33.05 82.53 31.58 82.53 29.98C82.53 28.38 82.78 26.88 83.27 25.54C83.77 24.2 84.48 23.05 85.4 22.1C86.33 21.15 87.45 20.42 88.76 19.9C90.08 19.39 91.53 19.13 93.12 19.13C94.71 19.13 96.2 19.39 97.52 19.91C98.84 20.43 99.96 21.17 100.9 22.14C101.84 23.11 102.56 24.25 103.04 25.58C103.53 26.91 103.77 28.37 103.77 29.98M98.41 29.98C98.41 27.8 97.93 26.11 96.97 24.9C96.01 23.69 94.73 23.08 93.11 23.08C92.07 23.08 91.15 23.35 90.35 23.89C89.56 24.43 88.94 25.23 88.51 26.28C88.08 27.34 87.86 28.57 87.86 29.98C87.86 31.37 88.07 32.59 88.5 33.64C88.92 34.68 89.53 35.48 90.32 36.03C91.11 36.59 92.04 36.86 93.11 36.86C94.73 36.86 96.02 36.25 96.97 35.03C97.93 33.81 98.41 32.13 98.41 29.97" fill="#80C343"/>
<path d="M111.56 22.08V22.72C112.5 21.48 113.53 20.57 114.64 19.99C115.75 19.41 117.03 19.12 118.48 19.12C119.93 19.12 121.15 19.43 122.26 20.04C123.37 20.65 124.19 21.52 124.74 22.64C125.09 23.3 125.32 24 125.43 24.75C125.54 25.51 125.59 26.47 125.59 27.64V37.58C125.59 38.65 125.35 39.46 124.86 40.01C124.37 40.56 123.73 40.83 122.95 40.83C122.17 40.83 121.51 40.55 121.01 39.99C120.51 39.43 120.27 38.63 120.27 37.58V28.68C120.27 26.92 120.03 25.57 119.53 24.64C119.04 23.71 118.07 23.24 116.6 23.24C115.65 23.24 114.78 23.52 114 24.09C113.22 24.66 112.64 25.44 112.28 26.43C112.02 27.23 111.89 28.71 111.89 30.89V37.58C111.89 38.67 111.64 39.47 111.14 40.01C110.64 40.55 109.99 40.82 109.19 40.82C108.39 40.82 107.79 40.54 107.29 39.98C106.8 39.42 106.55 38.62 106.55 37.57V22.14C106.55 21.12 106.77 20.36 107.21 19.86C107.66 19.36 108.26 19.11 109.03 19.11C109.5 19.11 109.92 19.22 110.3 19.44C110.68 19.66 110.98 19.99 111.21 20.44C111.44 20.88 111.55 21.42 111.55 22.06" fill="#80C343"/>
<path d="M144.53 31.29H134.09C134.09 32.5 134.35 33.57 134.82 34.5C135.3 35.43 135.93 36.12 136.72 36.59C137.51 37.06 138.38 37.3 139.33 37.3C139.97 37.3 140.55 37.22 141.08 37.07C141.61 36.92 142.12 36.69 142.61 36.37C143.11 36.05 143.56 35.71 143.98 35.34C144.4 34.97 144.94 34.48 145.6 33.85C145.87 33.62 146.26 33.5 146.77 33.5C147.32 33.5 147.77 33.65 148.1 33.95C148.44 34.25 148.61 34.67 148.61 35.22C148.61 35.7 148.42 36.26 148.04 36.91C147.66 37.55 147.09 38.17 146.33 38.77C145.57 39.36 144.61 39.86 143.46 40.25C142.3 40.64 140.98 40.84 139.48 40.84C136.05 40.84 133.38 39.86 131.48 37.91C129.58 35.95 128.62 33.3 128.62 29.95C128.62 28.37 128.85 26.91 129.32 25.56C129.79 24.21 130.48 23.05 131.38 22.09C132.28 21.13 133.38 20.38 134.7 19.87C136.02 19.35 137.48 19.1 139.08 19.1C141.16 19.1 142.96 19.54 144.45 20.42C145.94 21.3 147.06 22.43 147.81 23.83C148.55 25.23 148.92 26.65 148.92 28.09C148.92 29.43 148.54 30.3 147.77 30.7C147 31.1 145.92 31.3 144.52 31.3M134.08 28.27H143.76C143.63 26.44 143.14 25.08 142.29 24.17C141.44 23.26 140.31 22.81 138.92 22.81C137.53 22.81 136.5 23.27 135.65 24.19C134.8 25.11 134.28 26.47 134.1 28.27" fill="#80C343"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M171.43 53.4C171.43 53.4 157.01 50.89 155.89 50.89C154.77 50.89 148.21 49.63 140.11 48.94C132.01 48.24 125.87 48.1 121.12 47.68C116.37 47.26 113.02 46.98 105.48 46.64C97.94 46.3 90.12 46.28 84.53 46.14C78.94 46 66.94 47.12 66.19 47C65.44 46.88 65.12 47.53 64.14 48.23C63.16 48.93 59.45 48.93 59.45 48.93C59.45 48.93 51.57 49.63 46.26 50.6C40.95 51.58 40.81 52 38.4 52.55C35.99 53.1 19.03 55.77 17.07 56.17C15.3 56.54 9.15 59.01 8 59.48C7.88 59.53 7.81 59.56 7.81 59.56C7.76 60.41 25.45 57.73 25.45 57.73C25.45 57.73 24.61 56.89 31.17 55.64C37.73 54.38 62.59 51.73 67.47 51.17C69.1 50.98 73.43 50.95 78.79 50.99L79.59 50.59L90.33 51.14C96.24 51.24 102.08 51.36 106.24 51.41C107.43 51.11 109.3 50.68 110.22 50.68C111.64 50.68 126 51.98 126 51.98L126.68 52.24C144.41 53.31 168.86 55.08 168.86 55.08L172.25 53.52L171.38 53.39L171.43 53.4Z" fill="#F57E20"/>
<path d="M5.86 22.12V22.77C6.86 21.53 7.91 20.62 8.99 20.04C10.07 19.46 11.3 19.17 12.67 19.17C14.31 19.17 15.82 19.6 17.21 20.44C18.59 21.29 19.69 22.53 20.48 24.15C21.28 25.78 21.68 27.71 21.68 29.94C21.68 31.58 21.45 33.09 20.99 34.47C20.53 35.85 19.89 37 19.08 37.93C18.27 38.86 17.32 39.58 16.21 40.08C15.11 40.58 13.93 40.83 12.66 40.83C11.14 40.83 9.85 40.52 8.82 39.91C7.79 39.3 6.8 38.4 5.86 37.21V45.23C5.86 47.58 5.01 48.75 3.3 48.75C2.3 48.75 1.63 48.45 1.3 47.84C0.979998 47.24 0.809998 46.35 0.809998 45.19V22.15C0.809998 21.13 1.03 20.37 1.48 19.87C1.92 19.37 2.53 19.12 3.3 19.12C4.07 19.12 4.67 19.38 5.15 19.89C5.63 20.4 5.86 21.14 5.86 22.11M16.32 29.87C16.32 28.46 16.1 27.25 15.67 26.24C15.24 25.23 14.64 24.46 13.88 23.92C13.12 23.38 12.27 23.11 11.35 23.11C9.88 23.11 8.63 23.69 7.63 24.85C6.62 26.01 6.11 27.72 6.11 29.97C6.11 32.1 6.61 33.75 7.62 34.93C8.62 36.11 9.87 36.7 11.36 36.7C12.25 36.7 13.07 36.44 13.82 35.93C14.57 35.41 15.18 34.64 15.64 33.61C16.1 32.58 16.32 31.34 16.32 29.87Z" fill="#35479B"/>
</svg>
        </Link>
    <Link to={'/cart'} className="flex items-center justify-end w-10">
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_112_2)">
<path d="M10.677 24.758H21.028C21.3972 24.7581 21.7546 24.6277 22.037 24.3898C22.3193 24.1519 22.5085 23.8219 22.571 23.458L25.322 7.30098C25.3845 6.93709 25.5737 6.60703 25.856 6.36913C26.1384 6.13124 26.4958 6.00084 26.865 6.00098H27.892" stroke="#304659" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.6459 29.105C12.7232 29.105 12.7988 29.1279 12.8631 29.1709C12.9274 29.2138 12.9775 29.2749 13.0071 29.3464C13.0367 29.4178 13.0445 29.4964 13.0294 29.5723C13.0143 29.6481 12.977 29.7178 12.9224 29.7725C12.8677 29.8271 12.798 29.8644 12.7222 29.8795C12.6463 29.8946 12.5677 29.8868 12.4963 29.8572C12.4248 29.8276 12.3637 29.7775 12.3208 29.7132C12.2778 29.6489 12.2549 29.5733 12.2549 29.496C12.2549 29.3923 12.2961 29.2928 12.3694 29.2195C12.4427 29.1462 12.5422 29.105 12.6459 29.105Z" stroke="#304659" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.464 29.105C20.5413 29.105 20.6169 29.1279 20.6812 29.1709C20.7455 29.2138 20.7956 29.2749 20.8252 29.3464C20.8548 29.4178 20.8626 29.4964 20.8475 29.5723C20.8324 29.6481 20.7952 29.7178 20.7405 29.7725C20.6858 29.8271 20.6161 29.8644 20.5403 29.8795C20.4644 29.8946 20.3858 29.8868 20.3144 29.8572C20.2429 29.8276 20.1819 29.7775 20.1389 29.7132C20.0959 29.6489 20.073 29.5733 20.073 29.496C20.073 29.3923 20.1142 29.2928 20.1875 29.2195C20.2608 29.1462 20.3603 29.105 20.464 29.105Z" stroke="#304659" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.1519 20.075H10.5669C9.8695 20.0748 9.19214 19.8414 8.64258 19.412C8.09301 18.9825 7.70279 18.3817 7.53394 17.705L6.02294 11.654C5.99405 11.5387 5.99185 11.4183 6.01649 11.3019C6.04114 11.1856 6.09198 11.0764 6.16516 10.9827C6.23834 10.889 6.33193 10.8132 6.43881 10.7611C6.54569 10.709 6.66304 10.6819 6.78194 10.682H24.7449" stroke="#304659" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_112_2">
<rect width="33" height="33" fill="white"/>
</clipPath>
</defs>
</svg>

    </Link>
    </div>
  ) 
}

export default NavBar